/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-image: url('../../../assets/images/image.jpg');
} */

.card_img {
  background-image: url('../../../assets/images/scratch.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 70vh;
  border-radius: 30px;
  box-shadow: 10px 10px 0px 10px hsla(0, 8%, 69%, 0.815);
}
/* 
div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 20px;
  position: relative;
} */

.scratch-card {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
  cursor: pointer;
}

.popup {
  width: 100%;
  min-height: 100%;
  /* height: 100%; */
  position: absolute;
  bottom: -6rem;
}

.popup-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 50px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 50px 50px;
  width: 100%;
  width: 100%;
  min-height: 100vh;
}

.random_number {
  font-size: 30px;
  font-weight: 900;
  letter-spacing: 10px;
  display: inline-block;
}

.blink {
  animation: blink-animation 1s infinite;
}

@keyframes blink-animation {
  0%,
  100% {
    color: #ff0000;
  }
  50% {
    color: #00ff00;
  }
}

.popup img {
  margin-top: 20px;
  max-width: 100%;
  max-height: 200px;
}

.popUp-btn {
  padding: 10px 40px;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 700;
  border: none;
  z-index: 999;
  box-shadow: 0px 0px 2px 0px;
  border-radius: 6px;
  background-color: transparent;
  cursor: pointer;
}

.popUp-btn:hover {
  background-color: #9e4caf;
  color: #fff;
}

.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.splash-screen.hidden {
  opacity: 0;
  pointer-events: none;
}

.splash-screen h1 {
  font-size: 2rem;
}

.splash_image {
  background-image: url('../../../assets/images/splashimage.png');
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.scratch-card-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.prize-container {
  width: 320px;
  height: 320px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  text-align: center;
}

@keyframes blink-animation {
  0%,
  100% {
    color: #ff0000;
  }
  50% {
    color: #ffffff;
  }
}

.scratch-card-title {
  margin-bottom: 2rem;
  font-size: 44px;
  font-weight: 900;
  letter-spacing: 5px;
  display: inline-block;
  animation: blink-animation 1s infinite;
}
