/*
* Slot machine design inspired by Flaticon
* model url: https://www.flaticon.com/free-icon/slot-machine_536079#term=slot%20machine&page=1&position=46
*/

.Machine {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  flex-direction: column;
  height: 330px;
  width: 300px;
  background-color: #38aa97;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin: 20vh auto 5vh auto;
  position: relative;
}

.Machine::before {
  content: '';
  position: absolute;
  width: 27px;
  height: 330px;
  background-color: #42938a;
  left: -20px;
  border-top-left-radius: 8px;
  z-index: -20;
}

.Machine-bottom {
  background-color: #e3ed5a;
  width: 320px;
  height: 30px;
  margin: auto;
  margin-top: -31px;
  margin-bottom: 30px;
  border-radius: 5px;
  bottom: -30px;
  position: absolute;
  right: -15px;
}

.Machine-bottom::before {
  position: absolute;
  content: '';
  left: -40px;
  bottom: 0;
  background-color: #cddd54;
  height: 30px;
  width: 60px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  z-index: -10;
}

.Lights {
  position: absolute;
  top: 0;
}

.Light-bulb {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: white;
  margin: 20px 5px;
  box-shadow: #eaeaea 0px 1px 5px;
  animation: toYellow 1s infinite;
}
.Light-bulb:nth-child(2n) {
  background-color: #f7b647;
  box-shadow: #f7b647 0px 1px 5px;
  animation: toWhite 1s infinite;
}

.Reels {
  width: 300px;
  padding: 3px 30px;
  border-radius: 8px;
  position: relative;
}

.Reels::before {
  content: '';
  position: absolute;
  width: 22px;
  height: 100%;
  left: -20px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  z-index: -2;
  margin-top: -10px;
}

.Reel {
  display: inline-block;
  font-size: 58px;
  background-color: #eaeaea;
  border: #e3ed5a solid 0.5rem;
  border-radius: 5px;
  max-width: 80px;
  height: 80px;
  text-align: center;
  box-shadow: inset #3336 0px 1px 2px 1px;
  overflow: hidden;
}

.Reel-plate .span {
  display: block;
}

.Reel-plate-1 {
  animation: slideDown 1s;
}

.Reel-plate-1.spin {
  animation: spin 0.2s ease-out 0s 20;
}

.Reel-plate-2 {
  animation: slideDown 2s;
}

.Reel-plate-2.spin {
  animation: spin 0.2s ease-out 0s 20;
}

.Reel-plate-3 {
  animation: slideDown 3s;
}

.Reel-plate-3.spin {
  animation: spin 0.2s ease-out 0s 20;
}

.Slot {
  position: absolute;
  bottom: 45px;
  left: 15px;
  background-color: #eaf2ff;
  border-radius: 8px;
  padding: 15px 13px;
}

.Slot-inner {
  background-color: #175dc6;
  height: 40px;
  width: 15px;
  border-radius: 8px;
}

.slot-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ea2f12;
  border-radius: 5px;
  color: #fff;
  font-size: 32px;
  font-family: 'Roboto Slab', serif;
  box-shadow: -5px 1px 0px 1px #af200a, -2px 2px #af200a, -2px 2px #af200a;
  border: 1px solid #af200a;
  text-shadow: black 1px 1px 2px;
  transition: all 0.3s ease-in-out;
  outline: none;
}

.slot-button:hover,
.slot-button:focus {
  cursor: pointer;
  box-shadow: -2px 1px 0px 1px #af200a, -2px 2px #af200a, -2px 2px #af200a;
  outline: none;
}

.slot-button:focus {
  border: 1px solid #af200a;
}

.slot-button:disabled,
.slot-button[disabled] {
  border: 1px solid #d3867b;
  background-color: #fba092;
  box-shadow: -2px 1px 0px 1px #d3867b, -2px 2px #d3867b, -2px 2px #d3867b;
  cursor: not-allowed;
  outline: none;
}

.Message {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #333;
}

.Alarm-container {
  position: absolute;
  top: -75px;
}

.Alarm {
  background-color: #f84f35;
  height: 76px;
  width: 80px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: relative;
}

.Alarm::before {
  position: absolute;
  content: '';
  height: 76px;
  width: 35px;
  bottom: 0;
  left: -15px;
  background-color: #ea2f12;
  border-top-left-radius: 20px;
  z-index: -5;
}

.Alarm::after {
  background-color: #175dc6;
  height: 20px;
  width: 120px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 10;
  position: absolute;
  content: '';
  bottom: 0;
  left: -27px;
}

.Alarm-bulb {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #f9a02a;
  position: absolute;
  bottom: 10px;
  left: 15px;
}

.Alarm.alert {
  animation: pulse 1s infinite;
}

.Alarm.alert .Alarm-bulb {
  animation: toYellow 1s infinite;
}

.Message {
  position: absolute;
  bottom: -120px;
  font-size: 26px;
  opacity: 0;
  width: 350px;
}

.Message-visible {
  opacity: 1;
  transition: opacity 4s ease-in;
}

@keyframes toYellow {
  from {
    background-color: white;
    box-shadow: #eaeaea 0px 1px 5px;
  }
  to {
    background-color: #f7b647;
    box-shadow: #f7b647 0px 1px 5px;
  }
}

@keyframes toWhite {
  from {
    background-color: #f7b647;
    box-shadow: #f7b647 0px 1px 5px;
  }
  to {
    background-color: white;
    box-shadow: #eaeaea 0px 1px 5px;
  }
}

@keyframes pulse {
  from {
    background-color: #f84f35;
  }
  to {
    background-color: #ea2f12;
  }
}

@keyframes slideDown {
  from {
    margin-top: -150px;
  }
  to {
    margin-top: 0;
  }
}

@keyframes spin {
  0% {
    margin-top: -240px;
  }
  30% {
    margin-top: -200px;
  }
  50% {
    margin-top: -160px;
  }
  70% {
    margin-top: -80px;
  }
  90% {
    margin-top: 0;
  }
  100% {
    margin-top: -80px;
  }
}

@keyframes pulsePrize {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(0.85);
  }
  to {
    transform: scale(1);
  }
}
